@use "assets/styles" as *;

div.error-message-container {
  display: flex;
  justify-content: space-between;
  padding: rem(12);
  background-color: $error-background;
  border: rem(2) solid $error;
  border-radius: rem(8);

  div.error-message-icon {
    flex-grow: 0;
    width: rem(20);
    margin-top: rem(2);

    svg {
      width: rem(20);
      height: rem(20);
    }
  }

  div.error-message-content {
    flex-grow: 1;
    margin-left: rem(12);

    h1, p {
      @include lg-body;
      color: $primary-dark-gray;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    h1 {
      margin: 0;
    }

    p {
      font-weight: normal;
    }

    button {
      &.text-button {
        margin-top: rem(8);
        padding-left: rem(0);
      }
    }
  }
}