@use "assets/styles" as *;

button.tip-choice.btn {
  background-color: $primary-black;
  color: $white;
  text-align: center;
  margin: 0 rem(4);
  padding: rem(4) 0;
  border-radius: rem(8);
  position: relative;
  border: rem(1) solid $primary-black-secondary;

  &.selected {
    border-width: rem(2);
  }

  .tip-check-icon {
    position: absolute;
    width: rem(24);
    height: rem(24);
    top: 10%;
    right: -12%;
    z-index:$payment-footer-tip-check-icon-index;
    border: rem(2) solid $primary-dark-gray;
    border-radius: 50%;


    @include desktop {
      right: -6%;
    }

    circle.customization-check-circle {
      fill: $sheetz-red;
    }
  }

  p.description {
    @include sm-title;
  }

  p.amount {
    @include sm-body;
  }
}
