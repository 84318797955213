/** ------ Base colors ------ */
$sheetz-green: #8cb500;
$sheetz-green-highlight: #7da200;
$sheetz-red: #dd132c;
$sheetz-red-highlight: #ac0d21;
$pay-green: #359c57;
$pay-green-highlight: #308e4f;
$sheetz-blue: #3f9fd5;
$sheetz-blue-highlight: #2f7ab4;
$error-orange: #ec6a1e;
$error-orange-highlight: #d64f13;

/** ------ Accessibility colors ------ */
$error: #bd5518;
$error-dark: #e8854a;
$error-background: #fdf6f1;
$primary-black: #5e6c6e;
$primary-black-secondary: #c8cfd0;
$primary-black-tertiary: #acb7b8;
$primary-black-neutral: #75878a;

/** ------ Grays and whites ------ */
$body-bg: #e5e5e5;
$page-divider: $body-bg;
$border: $body-bg;
$off-white: #f1f1f1;
$white: #ffffff;
$primary-dark-gray: #2f3637;
$secondary-medium-gray: #586567;
$tertiary-gray: #88979a;
$light-gray: #c2d4d8;

/** ------ Vehicle colors ------ */
$vehicle-silver: #aaaaaa;
$vehicle-black: #111111;
$vehicle-blue: #3f94d5;
$vehicle-gray: #c2d4d8;
$vehicle-green: #359c57;
$vehicle-orange: #ec6a1b;
$vehicle-yellow: #ffa800;
$vehicle-navy: #142847;
$vehicle-red: #dd132c;
$vehicle-maroon: #740909;
$vehicle-purple: #8654a9;
$vehicle-tan: #d3b579;
$vehicle-other: #d717e2;
