@use "assets/styles/colors" as *;
@use "assets/styles/functions" as *;

$header-height: rem(70);
$tab-height: rem(60);
$item-customization-tabs-height: rem(55);
$payment-footer-height: rem(250);
$payment-footer-delivery-height: rem(355);
$payment-footer-in-store-height: rem(175);
$desktop-payment-footer-height: rem(145);
$desktop-payment-footer-delivery-height: rem(255);

$desktop-width: 640px;
$desktop-width-rem: rem(640);
$order-confirmation-desktop-width-rem: rem(1110);
$modal-desktop-height: 768px;
$desktop-modal-width: rem(500);

$order-config-height: rem(95);
$order-config-height-with-pickup-time: calc(#{$order-config-height} + #{rem(56)});
$order-config-sidebar-padding: rem(316);

// All z-index values live here so they are easier to manage
$button-footer-container-index: 2;

$image-replacement-index: -1;

$mobile-featured-item-image-order-complete-index: -1;
$mobile-featured-item-banner-index: 0;

$homepage-footer-bg-index: 0;
$homepage-footer-order-button-index: 1;

$header-index: 3;

$find-a-sheetz-map-index: 1;
$auth-child-index: 1;
$auth-title-index: 2;

$shopping-bag-button-index: 99;
$shopping-bag-overlay-index: 100;
$shopping-bag-index: 101;

$action-sheet-container-index: 300;
$action-sheet-overlay-index: 300;
$action-sheet-content-index: 301;

$modal-index: 400;

$loading-overlay-index: 500;
$loading-container-index: 501;

$order-confirmation-fixed-index: 2;

$payment-footer-index: 2;
$payment-footer-tip-check-icon-index: 2;

$sheetz-toast-index: 3;

$store-filter-button-row-index: 1;

$toast-index: 1;

$auto-index: auto;

$slider-index: 1;
$slider-content-index: 2;

$rip-svg-index: 1;

$savings-bar-index: 2;

$map-container-index: 0;
$map-overlay-index: 1;
$store-details-index: 2;

// Additional variables
$default-border: rem(1) solid $border;
