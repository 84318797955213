@use "assets/styles" as *;

div.sheetz-radio-button {
  input[type="radio"] {
    appearance: none;

    + label {

      span {
        display: inline-block;
        margin-top: rem(-10);
        @include lg-body;
        font-weight: normal;
      }

      &:before {
        content: '';
        background-color: $white;
        border-radius: rem(50);
        border: rem(1.5) solid $primary-dark-gray;
        display: inline-block;
        width: rem(20);
        height: rem(20);
        position: relative;
        top: rem(-2);
        margin-right: rem(12);
        vertical-align: top;
        cursor: pointer;
        text-align: center;
        transition: all 150ms ease;
      }
    }

    &:checked {
      + label {
        &:before {
          background-color: $sheetz-red;
          border: rem(2) solid $sheetz-red;
          box-shadow: inset 0 0 0 rem(2.5) $white;
        }
      }
    }

    &:focus {
      + label {
        &:before {
          outline: none;
          border-color: $sheetz-red;
        }
      }
    }

    &:disabled {
      + label {
        &:before {
          border-color: $primary-black-tertiary;
        }
      }
    }

    + label {
      &:empty {
        &:before {
          margin-right: 0;
        }
      }
    }
  }

  &.light-theme {
    input[type="radio"] {
      + label {
        &:before {
          background-color: transparent;
          border: rem(1.5) solid $white;
        }
      }

      &:checked {
        + label {
          &:before {
            background-color: $white;
            border: rem(2) solid $white;
            box-shadow: inset 0 0 0 rem(2.5) $primary-dark-gray;
          }
        }
      }

      &:disabled {
        + label {
          &:before {
            box-shadow: none;
            border-color: $primary-black-tertiary;
          }
        }
      }
    }
  }
}