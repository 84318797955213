@use "assets/styles" as *;

p.payment-exp-balance {
  @include sm-body;
  margin-top: rem(-2);
  margin-left: rem(12);

  &.low-funds-expired {
    display: flex;
    align-items: center;
    color: $error-dark;

    svg {
      width: rem(16);
      height: rem(16);
      margin-right: rem(4);

      circle {
        stroke: $error-dark;
      }

      path {
        fill: $error-dark;
      }
    }
  }
}